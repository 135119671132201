import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ApiService } from '../../providers/api.service';

import { WechatJsProvider } from '../../providers/wechat-js';
import { UtilService } from '../../../common/util';
// import { ENV } from '@app/env';
@Injectable()
export class CsyPayService {

  constructor(
    public apis: ApiService,
    public utils: UtilService,
    public wechatJs: WechatJsProvider,

  ) {
  }



  /**
   * @description 下单接口
   * @param {Object} orderParams 订单参数
   * orderParams: {
   *
   * }
   */
  unifiedOrder(orderParams) {

    return Observable.create(observer => {
      this.apis.unifiedPlaceOrder(orderParams).subscribe(res => {

        if (res.code == 0) {
          // 不用给钱，直接过
          if (res.msg != '') {
            // this.apis.showToast(res.msg);
          } else {
            this.apis.showToast('成功!');
          }
          // 查询订单
          const _payData = res.data

          // let Params = {
          //   'storeId': orderParams.storeId,
          //   'orderNo': JSON.parse(_payData).orderNo,
          // }
          observer.next(res)

          // this.notifyOrder(Params).subscribe((res) => {
          //   // observer.next(res)
          //   // observer.next();
          // })

        } else if (res.code == 2) {
          // 需要吊起线上支付
          const _payData = res.data
          observer.next(res)

          this.wechatJs.chooseWXPay(_payData).subscribe(() => {
            // 查询订单
            let Params = {
              'storeId': orderParams.storeId,
              'orderNo': JSON.parse(_payData).orderNo,
            }
            
            this.notifyOrder(Params).subscribe((res) => {
              observer.next(res)
              // observer.next();
            })
          }, err => {
            observer.error({ msg: '支付未完成' }); //支付失败
          })

        } else if (res.code == 1) {
          this.apis.showToast(res.msg);

          observer.error({ code: 10011, msg: res.msg, }); //下单失败
        }
      })
    })
  }

  /**
   * @description 统一支付接口(微信/会员/面对面)
   * @param {Object} payParams 支付参数
   * @param {Object} extraParams 其他参数
   * payParams:{
   *  orderId
   *  payAmt
   *  paytype
   *  orderType
   *  orderDetailList
   *  openGroupId
   * }
   * extraParams: {
   *  notifyOrder：微信支付后是否需要调单 true => 需要  false => 不需要
   *  successMsg： 支付成功提示语
   *  failMsg： 支付失败提示语
   * }
 */

  unifiedPay(payParams, extraParams: any = {
    notifyOrder: true,
    successMsg: '支付成功',
    failMsg: '支付失败'
  }) {
    return Observable.create(observer => {

      // let unifiedParams = {
      //   openSysChannel: this.csyGlobal.shopInfo.openSysChannel,
      //   openId: this.csyGlobal.openId,
      //   auth: `Bearer${this.csyGlobal.token}`,
      //   transType: 'p', //交易类型 p:支付 r:退款
      //   payChannel: 'xc',
      //   sign: this.utils.md5(`${payParams.orderId}p${payParams.payAmt}${params.custId}${params.storeId}${params.custCardNo}${params.storeSysCode}BearerTK2018Z`)
      // }

      this.apis.chargeCallsPay({ ...payParams, }).subscribe(res => {

        if (res.code == 0) {
          observer.next()
          if (res.msg != '') {
            this.apis.showToast(res.msg);
          } else {
            this.apis.showToast('成功!');
          }
        } else if (res.code == 2) {

          // 需要拉起线上支付
          const _payData = res.data
          
          this.wechatJs.chooseWXPay(_payData).subscribe(() => {
            let Params = {
              'storeId': payParams.storeId,
              'orderNo': JSON.parse(_payData).orderNo,
            }
            observer.next()

            this.notifyOrder(Params).subscribe((res) => {
              observer.next(res)
              // observer.next();
            })
          }, err => {
            observer.error({ msg: '支付未完成' }); //支付失败
          })

        } else if (res.code == 1) {
          this.apis.showToast(res.msg);
          observer.error({ code: 10011, msg: res.msg, }); //下单失败
        }
      })
    })
  }

  /**
   * @description 微信支付后调单
   * @param params 
   */
  notifyOrder(params) {
    return Observable.create(observer => {
      console.log('啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊');
      
      this.apis.chargeCallsQuery(params).subscribe(ns => {
        console.log(ns)
        if (ns.code == 0) {
          // this.helper.toast('调单成功');
          observer.next(true);
        } else {
          if (ns.errorCode == 504) {
            //主动调单跟微信回调有冲突，延迟一两秒再返回，避免订单状态未改变
            setTimeout(() => {
              observer.next(null);
            }, 1500)
          } else {
            // this.helper.toast(ns.msg);
            observer.next(null);
          }

        }
      }, () => {
        observer.next(null);
      })
    })
  }





}

/*
 * @Description: 日志服务类
 * @Author: 郑惜涛
 * @Date: 2019-06-29 17:28:46
 * @LastEditTime: 2019-06-29 17:33:26
 * @LastEditors: 郑惜涛
 */
import { Injectable } from '@angular/core';
// import { ENV } from '@app/env';
@Injectable()
export class Logger{

  constructor() {
  }

  public log(...objects: any[]) {
    this.log_real(console.log, LogLevel.Debug, objects);
  }

  public debug(...objects: any[]) {
    this.log_real(console.log, LogLevel.Debug, objects);
  }

  public info(...objects: any[]) {
    this.log_real(console.info, LogLevel.Info, objects);
  }

  public warn(...objects: any[]) {
    this.log_real(console.warn, LogLevel.Warning, objects);
  }

  public error(...objects: any[]) {
    this.log_real(console.error, LogLevel.Error, objects);
  }

  protected async log_real(func: Function, level: LogLevel, objects: any[]) {
    // const env = ENV.mode || 'dev';
    // if (env !== 'prod') {
    //   func.apply(console, objects);
    // }
  }
}

export enum LogLevel {
  Error,
  Warning,
  Info,
  Debug,
}
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { WechatJsProvider } from './providers/wechat-js';
import { Logger } from './providers/logger';
import { CsyGlobal } from './providers/CsyGlobal';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CsyPayService } from './services/csy/csy.pay.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_CONFIG, APP_DEV_CONFIG, APP_SIT_CONFIG, APP_UAT_CONFIG, APP_PRD_CONFIG } from '../app/config/app.config';
// import { DefaultInterceptor } from './core/service/InterceptorService';

// 零售服务类
const CSY_SERVICE = [
 CsyPayService,

];
// 公用服务类
const COMMON_SERVICE = [
 WechatJsProvider,
  Logger,
  CsyGlobal
];
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule,
    HttpClientModule,],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    // { provide: APP_CONFIG, useValue: APP_DEV_CONFIG },
    // { provide: APP_CONFIG, useValue: APP_SIT_CONFIG },
    // { provide: APP_CONFIG, useValue: APP_UAT_CONFIG },//测试
    { provide: APP_CONFIG, useValue: APP_PRD_CONFIG },//生产 
    ...CSY_SERVICE,
    ...COMMON_SERVICE,
    

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

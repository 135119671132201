import { InjectionToken } from '@angular/core';

/**
 * Created by Vince on 2017/7/18.
 */

export interface AppConfig {
  readonly UCENTER_SERVICE: string;
}

/** 本地开发环境 */
export const APP_DEV_CONFIG: AppConfig = {
  UCENTER_SERVICE: 'http://localhost:4008/'
};

/** 开发环境 */
export const APP_SIT_CONFIG: AppConfig = {
  UCENTER_SERVICE: 'https://sitwechat.aibaocloud.com/'
};

/** 准生产环境 */
export const APP_UAT_CONFIG: AppConfig = {
  // UCENTER_SERVICE: 'https://uatmsy.aibaocloud.com/'
  UCENTER_SERVICE: 'https://sit.aibaocloud.com/' 
  

};

/** 生产环境 */
export const APP_PRD_CONFIG: AppConfig = { 
  UCENTER_SERVICE: 'https://wechat.aibaocloud.com/'
};

/** 访问waimai域名 测试 */
// export const WAIMAI_API_CONFIG =  'https://sit.aibaocloud.com/';

/** 访问waimai域名 生产 */ 
export const WAIMAI_API_CONFIG = 'http://pos.jxzbfood.com/';






// // apiWaimai = 'https://sit.aibaocloud.com/';
// apiWaimai = 'http://pos.jxzbfood.com/';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

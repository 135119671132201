import { Injectable } from '@angular/core';

@Injectable()
export class CsyGlobal {
    public access_token: string = ''  //TOKEN

   
    set unionId(value: string) {
        this.access_token = value;
    }

    get unionId(): string {
        return this.access_token;
    }

   
}
import { Injectable } from '@angular/core';
import wx from 'weixin-js-sdk';
import sha1 from 'js-sha1';
import { ApiService } from './api.service';
import { Logger } from './logger';
// import { GlobalData } from './GlobalData';
// import { HelperService } from './Helper';
// import { Observable } from 'rxjs/Rx';
import { Observable } from 'rxjs'
// import { ENV } from '@app/env';
// import { MsyApis } from './MsyApis';
// import { CsyGlobalService } from '@csy/services/csy.global.service';

/*
  微信 js sdk 封装类
  npm install weixin-js-sdk

  npm install js-sha1
*/
@Injectable()
export class WechatJsProvider {

  // 生成签名的时间戳
  timestamp: any = "1562059495";
  // 生成签名的随机串
  nonceStr: string = "ujz3bc3lz1i";

  constructor(public apis: ApiService,
    // public helper: HelperService,
    public logger: Logger,
    // public msyApis: MsyApis,
    // public csyGlobal: CsyGlobalService,
  ) {
  }
  /** 初始化配置 */
  init(call?, parm?, isMsy?) {
    var apis: any = this.apis;
    if (isMsy) {
      // apis = this.msyApis
    }
    return Observable.create(obs => {
      this.doInit(apis, parm, obs);
    })
  }
  msyInit(call?, parm?,) {
    return Observable.create(obs => {
      // this.doInit(this.msyApis, parm, obs);
    })
  }

  private doInit(apis: any, parm: any, obs?) {
    apis.getTicket(parm).subscribe(res => {
      this.initValidateTicket(res, obs)
    }, err => {
      obs && obs.next();
    });
  }

  initValidateTicket(res, obs?) {
    if (res.code == 0) {

      // this.csyGlobal.appId = res.data.appid;
      obs && obs.next();
      // 生成加密签名
      let signature = sha1.hex("jsapi_ticket=" + res.data.tickets + "&noncestr=" + this.nonceStr + "&timestamp=" + this.timestamp + "&url=" + location.href.split('#')[0]);
      wx.config({
        // beta: false, // 开启内测接口调用，注入wx.invoke方法
        debug: false, // 开启调试模式
        appId: res.data.appid, // 第三方app唯一标识
        timestamp: this.timestamp, // 生成签名的时间戳
        nonceStr: this.nonceStr, // 生成签名的随机串
        signature: signature, // 签名
        jsApiList: [ // 需要使用的jsapi列表
          'checkJsApi',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareWeibo',
          'getLocation',
          'chooseImage',
          'previewImage',
          'uploadImage',
          'downloadImage',
          "checkIsSupportFaceDetect",
          "requestWxFacePictureVerify",
          "translateVoice",
          "getLocalImgData",
          "openLocation",
          "scanQRCode",
          "startRecord",
          "stopRecord",
          "chooseWXPay",
        ]
      });
      // wx.ready(() => {
      //   GlobalData.isUseWxsdk = true;
      // });
      // wx.error((res) => {
      //   this.logger.error(res);
      //   GlobalData.isUseWxsdk = false;
      // });
    } else {
      obs && obs.next();
      this.logger.error(res.msg);
      // GlobalData.isUseWxsdk = false;
    }
  }

  /** 微信分享设置 */
  share(title, desc, link, imgUrl) {
    wx.ready(() => {
      wx.onMenuShareTimeline({
        title,
        link,
        imgUrl,
        success: () => {
          this.logger.info('配置分享成功');
        }
      });
      wx.onMenuShareAppMessage({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        success: () => {
          this.logger.info('配置分享成功');
        }
      });
      wx.updateAppMessageShareData({
        title,
        desc,
        link,
        imgUrl,
        success: () => {
          this.logger.info('配置分享成功');
        }
      });
      wx.updateTimelineShareData({
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: () => {
          this.logger.info('配置分享成功');
        }
      })
      wx.onMenuShareWeibo({
        title,
        desc,
        link,
        imgUrl,
        success: function () {
          // 用户确认分享后执行的回调函数
          this.logger.info('配置分享成功');
        },
        cancel: function () {
          // 用户取消分享后执行的回调函数
          this.logger.info('取消配置分享');
        }
      });
    });
  }

  /** 定位 */
  getLocation(call) {
    // call();
    wx.ready(function () {
      wx.getLocation({
        type: 'gcj02',
        success: function (res) {
          call(res);
        },
        fail: function (res) {
          call()
        },
        cancel: function (res) {
          call();
        }
      });
    });
    // if (ENV.mode == 'sit') {
    //   call();
    // }

  };

  // /** 图片选择上传 */
  // chooseImage(count, userPk, success, type1?, type2?) {
  //   let mediaId = [];
  //   let uploadImgs = [];
  //   let my = this;
  //   let sizeType = [];
  //   let sourceType = [];
  //   // 图片，相册
  //   if (sizeType) {
  //     sizeType = type1;
  //   } else {
  //     sizeType = ['original', 'compressed']
  //   }
  //   if (sourceType) {
  //     sourceType = type2;
  //   } else {
  //     sourceType = ["album", "camera"];
  //   }
  //   wx.chooseImage({
  //     count: count, // 默认9
  //     sizeType: sizeType, // 可以指定是原图还是压缩图，默认二者都有
  //     sourceType: sourceType, // 可以指定来源是相册还是相机，默认二者都有  'album'：相册, 'camera':相机
  //     success: function (res) {
  //       let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
  //       for (let key in localIds) {
  //         var localId = localIds[key];
  //         (function (localIde) {
  //           // 上传图片
  //           wx.uploadImage({
  //             localId: localIde, // 需要上传的图片的本地ID，由chooseImage接口获得
  //             isShowProgressTips: 1, // 默认为1，显示进度提示
  //             success: function (ress) {
  //               let serverId = ress.serverId; // 返回图片的服务器端ID
  //               mediaId.push(serverId);
  //               // if(localIds.length == mediaId.length){
  //               console.log(JSON.stringify(mediaId));
  //               // 上传图片
  //               // my.api.uploadByMediaId({
  //               //   appid: AppGlobal.WX_JSAPI_APPID,
  //               //   mediaIds: mediaId.join(",")
  //               // }).then((data)=>{

  //               my.api.uploadCommImg({
  //                 userPk: userPk,
  //                 media_id: serverId,
  //                 appid: AppGlobal.WX_JSAPI_APPID
  //               }).then((data) => {
  //                 uploadImgs = [];
  //                 console.log("微信上传图片----------------");
  //                 console.log(JSON.stringify(data));
  //                 if (data.state == "200") {
  //                   var filesss = data.data.file.split(",");
  //                   for (key in filesss) {
  //                     uploadImgs.push(filesss[key]);
  //                   }
  //                   success(uploadImgs);
  //                   my.ref.tick();
  //                 } else {
  //                   my.popUp.presentToast(data.message);
  //                 }
  //               })

  //               //}
  //             }
  //           });

  //         })(localId);
  //       }
  //     }
  //   });
  // }

  /** 微信支付 */
  chooseWXPay(data) {
    return Observable.create(observer => {
      console.log(data);
      const result = JSON.parse(data)
      console.log({
        appId: result.appId,
        timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
        package: result.package_str, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
        signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: result.paySign, // 支付签名
      })
      // 生成加密签名
      let signature = sha1.hex("jsapi_ticket=" + '12' + "&noncestr=" + result.nonceStr + "&timestamp=" + result.timestamp + "&url=" + location.href.split('#')[0]);
      wx.config({
        // beta: false, // 开启内测接口调用，注入wx.invoke方法
        debug: false, // 开启调试模式
        appId: result.appId, // 第三方app唯一标识
        timestamp: this.timestamp, // 生成签名的时间戳
        nonceStr: this.nonceStr, // 生成签名的随机串
        signature: signature, // 签名
        jsApiList: [ // 需要使用的jsapi列表
          "chooseWXPay",
        ]
      });
      wx.chooseWXPay({
        appId: result.appId,
        timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
        package: result.package_str, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
        signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: result.paySign, // 支付签名
        success: (res) => {
          //支付后回调
          this.logger.info(res)
          // 支付成功后的回调函数
          if (res.errMsg == "chooseWXPay:ok") {
           
            observer.next(res);
          } else {
            observer.error(res.errMsg);
            // fail(res.errMsg);
          }
        },
        cancel: (res) => {
          // fail("支付取消");
          observer.next(res);

          observer.error("支付取消");
        },
        fail: (res) => {
          observer.next(res);

          this.logger.info('支付失败')
          observer.error("支付失败");
        }
      });
    })


  }

  openLocation(latitude, longitude, name, address) {
    wx.ready(function () {
      wx.openLocation({
        latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
        name: name, // 位置名
        address: address, // 地址详情说明
        scale: 23, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      });
    });
  }

  settingLocation() {
    return Observable.create(observer => {
      wx.getSetting({
        success: (res) => {
          let authSetting = res.authSetting
          if (authSetting['scope.userLocation']) {
            // 已授权
            observer.next()
          } else {
            wx.showModal({
              title: '您未开启地理位置授权',
              content: 'xxxx',
              success: res => {
                if (res.confirm) {
                  wx.openSetting()
                  observer.next()
                }
              },
              fail: err => observer.error('定位失败,请重试'),
              cancel: () => observer.error('您拒绝了开启地理定位')
            })
          }
        }
      })
    })
  }

  //开始录音
  startRecord() {
    return Observable.create(observer => {
      wx.startRecord({
        success: () => {
          observer.next();
        },
        fail: (res) => {
          observer.error('录音出错，请重试');
        },
        cancel: () => {
          observer.error('您拒绝录音');
        }
      });
    })
  }

  //停止录音
  stopRecord() {
    return Observable.create(observer => {
      wx.stopRecord({
        success: (res) => {
          observer.next(res.localId)
        },
        fail: () => {
          observer.error('录音错误，请重试')
        }
      });
    })
  }


  //智能识别
  translateVoice(localId) {
    return Observable.create(observer => {
      wx.translateVoice({
        localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: (res) => {
          observer.next(res.translateResult);
        },
        fail: () => {
          observer.error('智能识别失败');
        }
      });
    })
  }

  /**
  * 判断是否有网络
  */
  getConnectionStatus() {
    return Observable.create(observer => {
      this.getNetworkType().subscribe(res => {
        if (res !== 'none') {
          observer.next(true)
        } else {
          observer.next(false)
        }
      })
    });
  }

  /**
   *  返回网络类型2g，3g，4g，wifi
  */
  getNetworkType() {
    return Observable.create(observer => {
      wx.getNetworkType({
        success: (res) => {
          observer.next(res.networkType)
        },
        fail: () => {
          observer.next('none');
        }
      });
    })

  }


  /**
   * 微信扫一扫
  */
  scanQRCode() {
    return Observable.create(observer => {
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: (res) => {
          observer.next(res.resultStr.split(',')[1]);// 当needResult 为 1 时，扫码返回的结果 注意，扫码得出结果带编码类型，"CODE_128,1xxxxx"
        }
      });
    })
  }


}

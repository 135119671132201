import { Injectable, Inject } from '@angular/core';
import { Observable, of, } from 'rxjs';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { LoadingController, ToastController } from '@ionic/angular';
import { AppConfig, APP_CONFIG, WAIMAI_API_CONFIG } from '../config/app.config';

import { CsyGlobal } from '../providers/CsyGlobal';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = "http://localhost:3000/api/v1/products";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    public loadingController: LoadingController,
    @Inject(APP_CONFIG) private config: AppConfig,
    public toastController: ToastController,
    public CsyGlobal: CsyGlobal,
    
  ) { WAIMAI_API_CONFIG}
  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  

  /* getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(apiUrl)
      .pipe(
        tap(heroes => console.log('fetched products')),
        catchError(this.handleError('getProducts', []))
      );
  }

  getProduct(id): Observable<Product> {
    const url = `${apiUrl}/${id}`;
    return this.http.get<Product>(url).pipe(
      tap(_ => console.log(`fetched product id=${id}`)),
      catchError(this.handleError<Product>(`getProduct id=${id}`))
    );
  }

  addProduct(product): Observable<Product> {
    return this.http.post<Product>(apiUrl, product, httpOptions).pipe(
      tap((product: Product) => console.log(`added product w/ id=${product._id}`)),
      catchError(this.handleError<Product>('addProduct'))
    );
  }

  updateProduct(id, product): Observable<any> {
    const url = `${apiUrl}/${id}`;
    return this.http.put(url, product, httpOptions).pipe(
      tap(_ => console.log(`updated product id=${id}`)),
      catchError(this.handleError<any>('updateProduct'))
    );
  }

  deleteProduct(id): Observable<Product> {
    const url = `${apiUrl}/${id}`;

    return this.http.delete<Product>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted product id=${id}`)),
      catchError(this.handleError<Product>('deleteProduct'))
    );
  } */

  getBalance(data: { page, limit, card_id, encrypt_code, openid }): Observable<any> {
    // debugger
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/balance?${this.serialize(data)}`, { isLoading: true });
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/balance?${this.serialize(data)}`)
    //   .pipe(
    //     tap(heroes => console.log('fetched getBalance')),
    //     catchError(this.handleError('getProducts', []))
    //   );
  }

  getIntegral(data: { page, limit, card_id, encrypt_code, openid }): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/integral?${this.serialize(data)}`, { isLoading: true });
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/integral?${this.serialize(data)}`)
    //   .pipe(
    //     tap(heroes => console.log('fetched getIntegral')),
    //     catchError(this.handleError('getProducts', []))
    //   );
  }


  getMember(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/vipInfo?${this.serialize(data)}`, { isLoading: true });
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/vipInfo?${this.serialize(data)}`)
    //   .pipe(
    //     tap(heroes => console.log('fetched getBalance')),
    //     catchError(this.handleError('getProducts', []))
    //   );
  }
  // 获取用户提交资料(跳转型一键激活)
  activatetempinfo(data): Observable<any> {
    return this.http.post<any>(`${this.config.UCENTER_SERVICE}/wechat/api/card/activatetempinfo`, data, httpOptions).pipe(
      tap(product => console.log(`fetched getBalance`)),
      catchError(this.handleError('addProduct'))
    );
  }
  // 获取基础设置
  getWechatSetting(data): Observable<any> {
    return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/api/param/getWechatBaseSetting?${this.serialize(data)}`).pipe(
      tap(product => console.log(`fetched getBalance`)),
      catchError(this.handleError('addProduct'))
    );
  }
  // 接口激活会员卡(跳转型一键激活)
  activateCard(data): Observable<any> {
    return this.http.post<any>(`${this.config.UCENTER_SERVICE}/wechat/api/card/activate`, data, httpOptions).pipe(
      tap(product => console.log(`fetched getBalance`)),
      catchError(this.handleError('addProduct'))
    );
  }

  // 获取手机验证码
  getMobileCode(data): Observable<any> {
    return this.http.get<any>(`${this.config.UCENTER_SERVICE}/wechat/api/sysCode/getCode?${this.serialize(data)}`)
      .pipe(
        tap(heroes => console.log('fetched getBalance')),
        catchError(this.handleError('getProducts', []))
      );
  }

  // 微会员信息
  getCustSalesInfo(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getCustSalesInfo?${this.serialize(data)}`, { isLoading: true });
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/getCustSalesInfo?${this.serialize(data)}`).pipe(
    //   tap(product => console.log(`fetched getBalance`)),
    //   catchError(this.handleError('addProduct'))
    // );
  }

  // 微会员消费记录
  getPosSalesdetailPage(data): Observable<any> {
    
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getPosSalesdetailPage?${this.serialize(data)}`, { isLoading: true });
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/getPosSalesdetailPage?${this.serialize(data)}`).pipe(
    //   tap(product => console.log(`fetched getBalance`)),
    //   catchError(this.handleError('addProduct'))
    // );
  }

  // 微会员次卡
  getPosCustitemtimesledgerPage(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getPosCustitemtimesledgerPage?${this.serialize(data)}`, { isLoading: true });
  }

  // 微会员寄存记录
  getPosCustdepositledgerPage(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getPosCustdepositledgerPage?${this.serialize(data)}`, { isLoading: true });
  }

  // 微会员赊账
  getPosCustchargeledgerPage(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getPosCustchargeledgerPage?${this.serialize(data)}`, { isLoading: true });
  }

  // 微会员储值卡
  getPosCustrechargeledgerPage(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getPosCustrechargeledgerPage?${this.serialize(data)}`, { isLoading: true });
  }


  // 微会员购物卡
  getPosCustshoppingcardPage(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getPosCustshoppingcardPage?${this.serialize(data)}`, { isLoading: true });
  }

  // 微会员优惠券
  getCustCouponPage(data): Observable<any> {
    return this.get(`${this.config.UCENTER_SERVICE}wechat/service/getCustCouponPage?${this.serialize(data)}`, { isLoading: true });
  }


  
  // 话费充值业务-- 访问的服务器域名不同，分开处理
  // 6.4获取会员可用的话费券(分页查询)
  posWaimaiInterfaceTrans(data): Observable<any> {
    return this.get(`${WAIMAI_API_CONFIG}waimai/posWaimaiInterfaceTrans/page?${this.serialize(data)}`, { isLoading: true });
  }
  // 获取Token
  genWxMicroCustInAccessToken(data): Observable<any> {
    return this.get(`${WAIMAI_API_CONFIG}waimai/aposCsy/genWxMicroCustInAccessToken?${this.serialize(data)}`, { isLoading: true });
  }
  getCouponPage(data): Observable<any> {
    return this.get(`${WAIMAI_API_CONFIG}waimai/posCouponh/getCustCouponPage?${this.serialize(data)}`, { isLoading: true });
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/getCustSalesInfo?${this.serialize(data)}`).pipe(
    //   tap(product => console.log(`fetched getBalance`)),
    //   catchError(this.handleError('addProduct'))
    // );
  }

  // 话费充值下单
  unifiedPlaceOrder(data): Observable<any> {
    return this.post(`${WAIMAI_API_CONFIG}waimai/shop/order/couponChargeCalls`, data, { isLoading: true });
    return this.http.post<any>(`${WAIMAI_API_CONFIG}waimai/shop/order/couponChargeCalls`, data, this.getHeaders(data)).pipe(
      tap(product => console.log(`fetched getBalance`)),
      catchError(this.handleError('addProduct'))
    );
  }

  // 话费充值线上支付
  chargeCallsPay(data): Observable<any> {
    return this.post(`${WAIMAI_API_CONFIG}waimai/shop/order/chargeCallsPay`, data, { isLoading: true });

    return this.http.post<any>(`${WAIMAI_API_CONFIG}waimai/shop/order/chargeCallsPay`, data, this.getHeaders(data)).pipe(
      tap(product => console.log(`fetched getBalance`)),
      catchError(this.handleError('addProduct'))
    );
  }

  // 获取充值订单详情
  posWaimaiInterfacerecharge(storeId,data): Observable<any> {

    return this.http.get<any>(`${WAIMAI_API_CONFIG}waimai/posWaimaiInterfaceTrans/` + storeId+'/'+ data).pipe(
      tap(product => console.log(`fetched getBalance`)),
      catchError(this.handleError('addProduct'))
    );

  }

  // 话费充值查询订单状态
  chargeCallsQuery(data): Observable<any> {
    return this.post(`${WAIMAI_API_CONFIG}waimai/shop/order/chargeCallsQuery`, data, { isLoading: true });

  }

  // 话费充值取消订单
  chargeCallsCancle(data): Observable<any> {
    return this.post(`${WAIMAI_API_CONFIG}waimai/shop/order/chargeCallsCancle`, data, { isLoading: true });

  
  }


  




  

  

  /**
  * json参数处理
  * @param {{}} json
  * @returns {string}
  */
  serialize(json = {}) {
    let paramArr = [];
    if (Object.keys(json).length > 0) {
      for (let key in json) {
        if (json[key] != null) {
          
          // if (key == 'startDate' || key == 'endDate') {
          //   paramArr.push(key + '=' + json[key])

          // }else{
          //   paramArr.push(key + '=' + encodeURIComponent(json[key]))

          // }
          paramArr.push(key + '=' + encodeURIComponent(json[key]))

        } else {
          paramArr.push(key + '=' + null)
        }
      }
    }

    return encodeURI(paramArr.join('&'))
  }

  /**
   * 获取优惠券
   * @param data 
   */
  getCoupon(data): Observable<any> {
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/vipInfo?${this.serialize(data)}`)
    return this.get(`${this.config.UCENTER_SERVICE}/wechat/service/coupon?${this.serialize(data)}`, { isLoading: true });
  }

  /**
 * 获取可领取优惠券
 * @param data 
 */
  wechatListAvailableCoupons(data): Observable<any> {
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/vipInfo?${this.serialize(data)}`)
    return this.get(`${this.config.UCENTER_SERVICE}/wechat/service/wechatListAvailableCoupons?${this.serialize(data)}`, { isLoading: true });
    // return this.get(` http://192.168.1.245:9999/custs/posCouponh/wechatListAvailableCoupons?${this.serialize(data)}`, { isLoading: true });
  }

  /**
* 领取优惠券
* @param data 
*/
  wechatReceiveCoupon(data): Observable<any> {
    // return this.http.get<any>(`${this.config.UCENTER_SERVICE}wechat/service/vipInfo?${this.serialize(data)}`)
    return this.get(`${this.config.UCENTER_SERVICE}/wechat/service/wechatReceiveCoupon?${this.serialize(data)}`, { isLoading: true });
  }

  // 时间格式化
  dateFormats(date, format: string='') {
    let time = format == "" ? "YYYYmmddHHMMSS" : format
    let ret;
    date = new Date(date);
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString() // 秒
    }
    for (let k in opt) {
      ret = new RegExp('(' + k + ')').exec(time)
      if (ret) {
        time = time.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
        )
      }
    }
    return time
  }
  getQueryString(url) {
    var url = url; //获取url中"?"符后的字串
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      let  strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  }
  getHeaders(data){
    let time = new Date()
    let jktranstionid = 'wechat' + '-' + data['openId'] + '-' + this.dateFormats(time, "YYYYmmddHHMMSS") + '-' + Number(
      Math.random().toString().substr(3, 5))
    let headers = { headers: { "jktranstionid": jktranstionid, "Authorization": this.CsyGlobal.access_token } }
    return headers
  }
  get(url, set: { isLoading: true, loadingMsg?: '数据加载中...' }) {
    return Observable.create(obs => {
      var lod
      if (set && set.isLoading) {
        this.loadingController.create({
          message: set.loadingMsg || '',
        }).then(loading => {
          let isReture = false;
          lod = loading;
          lod.present();

          setTimeout(() => {
            if (isReture) {
              return;
            }
            lod && lod.dismiss();
            this.showToast('请求超时');
            return obs.next({});
            isReture = true;
          }, 15000);
          let time = new Date()
          let jktranstionid = 'wechat' + '-' + this.getQueryString(url)['openId'] + '-' + this.dateFormats(time,"YYYYmmddHHMMSS") + '-' + Number(
            Math.random().toString().substr(3, 5)) 
          
          
          var headers = { headers: { "jktranstionid": jktranstionid}}

          this.http.get<any>(url, headers)
            .pipe(
              tap(heroes => console.log('fetched getBalance')),
              catchError(this.handleError('getProducts', []))
            ).subscribe(data => {
              if (isReture) {
                return;
              }
              obs.next(data);
              lod && lod.dismiss();
              this.doHttpSeting(data, set);
              isReture = true;
            }, error => {
              if (isReture) {
                return;
              }
              lod && lod.dismiss();
              obs.error(error);
              this.showToast('请求失败');
              isReture = true;
              // console.log('请求失败');
            });
        });
      }
    });
  }

  post(url, parms, set: { isLoading: true, loadingMsg?: '数据加载中...' }) {
    
    return Observable.create(obs => {
      var lod
      if (set && set.isLoading) {
        this.loadingController.create({
          message: set.loadingMsg || '请求数据中...',
        }).then(loading => {
          lod = loading;
          lod.present();
          let time = new Date()
          console.log(parms);
          
          let jktranstionid = 'wechat' + '-' + parms == undefined ? '' : ''+ '-' + this.dateFormats(time, "YYYYmmddHHMMSS") + '-' + Number(
            Math.random().toString().substr(3, 5))

            
          var headers = { headers: { "jktranstionid": jktranstionid, "Authorization": this.CsyGlobal.access_token } }
          this.http.post<any>(url, parms, headers)
            .pipe(
              tap(heroes => console.log('fetched getBalance')),
              catchError(this.handleError('getProducts', []))
            ).subscribe(data => {
              obs.next(data);
              lod && lod.dismiss();
              this.doHttpSeting(data, set);
            }, error => {
              lod && lod.dismiss();
              obs.error(error);
              this.showToast('请求失败')
            });
        });
      }
    });
  }

  doHttpSeting(res, set) {
    // debugger
    if (res.code != '0' && !res.records && set.isLoading && !res.data) {
      setTimeout(() => {
        this.showToast(res.msg);
      }, 200);

    }
  }
  async showToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top',
      showCloseButton: true,
      closeButtonText: '关闭',
    });
    toast.present();
  }



  
}

import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

/**
 * 工具服务类
 */
@Injectable({
    providedIn: 'root',
})
export class UtilService {
    /**
 * 加法函数，用来得到精确的加法结果
 *说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 *调用：accAdd(arg1,arg2)
 * 返回值：arg1加上arg2的精确结果
 * num:保留小数位 默认两位
 */
    accAdd = (arg1, arg2, num = 2) => {
        arg1 = arg1 ? arg1 : 0;
        arg2 = arg2 ? arg2 : 0;
        let r1, r2, m, c;

        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }

        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }

        c = Math.abs(r1 - r2);
        m = Math.pow(10, Math.max(r1, r2));

        if (c > 0) {
            let cm = Math.pow(10, c);

            if (r1 > r2) {
                arg1 = Number(arg1.toString().replace(".", ""));
                arg2 = Number(arg2.toString().replace(".", "")) * cm;
            } else {
                arg1 = Number(arg1.toString().replace(".", "")) * cm;
                arg2 = Number(arg2.toString().replace(".", ""));
            }
        } else {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", ""));
        }
        let tmp = (arg1 + arg2) / m;
        return this.formatNumDecimals(tmp, num, false)

    }

    /**
     * 减法函数，用来得到精确的减法结果
     *说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
     * 调用：accSub(arg1,arg2)
     * 返回值：arg1加上arg2的精确结果
     * num:保留小数位 默认两位
     */
    accSub = (arg1, arg2, num = 2) => {
        arg1 = arg1 ? arg1 : 0;
        arg2 = arg2 ? arg2 : 0;
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }

        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }

        m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2;
        let tmp = ((arg1 * m - arg2 * m) / m).toFixed(n);
        return this.formatNumDecimals(tmp, num, false)
    }

    /**  
 * 小数位数超过设定值，保留的小数位数
 * @param maxNum保留的最大小数位数 | maxLength保留的小数位数 | forceFmt是否强制格式化
 */
    formatNumDecimals = (num, maxLength, forceFmt) => {
        num = Number(num);
        let x = String(num).indexOf('.') + 1; //小数点的位置
        let y = String(num).length - x; //小数的位数
        if (forceFmt || (y > 0 && y > maxLength)) {
            num = num.toFixed(maxLength); //返回字符串
        }
        return Number(num);
    }


    md5(str) {
        return Md5.hashStr(str);
    }

}